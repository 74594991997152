export const DEFAULT_USER_ROLES = ["pltrackit", "myprefs"];
export const COLLECTIONS_SHIPEASE_FIELD = "shipEase";
export const DELIVERY_EXCEPTIONS_FIELD = "exceptions";
export const CHANGE_DELIVERY_FIELD = "change_delivery";
export const PLREPORTS_FIELD = "plreports";
export const DISCOLLECT_FIELD = "discollect";
export const DISABLE_PAPERLESS_TRADING_FIELD = "disablePaperlessTrading";
export const EXREPORTS_FIELD = "exreports";
export const PLREVIEWIT_ROLE = "plreviewit";
export const COLLECTIONS_SHIPEASE_ROLE = "shipease";
export const RFI_ROLE = "rfi";
export const IEEXCEPTIONS_ROLE = "ieexceptions";
export const TRK_ADMIN_ROLE = "trkAdmin";
export const RESTRICT_ACTIONS_ROLE = "restrictactions";
export const ALL_APP_ROLES = [
  "pltrackit",
  "myprefs",
  COLLECTIONS_SHIPEASE_FIELD,
  DELIVERY_EXCEPTIONS_FIELD,
  CHANGE_DELIVERY_FIELD,
  PLREPORTS_FIELD,
  DISCOLLECT_FIELD,
  EXREPORTS_FIELD,
  PLREVIEWIT_ROLE,
  COLLECTIONS_SHIPEASE_ROLE,
  RFI_ROLE,
  IEEXCEPTIONS_ROLE,
  TRK_ADMIN_ROLE,
  RESTRICT_ACTIONS_ROLE,
];
