import { get } from "lodash";

import { isAdminAccount } from "../../customer/customer.model";
import { isDpdBusinessId } from "../../user/user.model";
import {
  COLLECTIONS_SHIPEASE_FIELD,
  COLLECTIONS_SHIPEASE_ROLE,
  DEFAULT_USER_ROLES,
  DELIVERY_EXCEPTIONS_FIELD,
  DISABLE_PAPERLESS_TRADING_FIELD,
  IEEXCEPTIONS_ROLE,
  PLREVIEWIT_ROLE,
  RFI_ROLE,
  TRK_ADMIN_ROLE,
} from "./systemAccess.constants";

export const prepareSystemAccess = (formValues, { businessId, account }) => {
  const appRoles = Object.entries(formValues).reduce((acc, [key, value]) => {
    if (!value) {
      return acc;
    }

    switch (key) {
      case COLLECTIONS_SHIPEASE_FIELD: {
        acc.push(COLLECTIONS_SHIPEASE_ROLE, PLREVIEWIT_ROLE);
        break;
      }
      case DELIVERY_EXCEPTIONS_FIELD: {
        acc.push(isDpdBusinessId(businessId) ? RFI_ROLE : IEEXCEPTIONS_ROLE);
        break;
      }
      case DISABLE_PAPERLESS_TRADING_FIELD: {
        break;
      }
      default:
        acc.push(key);
    }

    return acc;
  }, DEFAULT_USER_ROLES.slice());

  isAdminAccount(account) && appRoles.push(TRK_ADMIN_ROLE);

  return { appRoles };
};

export const prepareCustomerAdminAccounts = (formValues) => {
  const accounts = get(formValues, "accounts", []);

  return { customerAdminAccounts: accounts.map(({ account }) => account) };
};

export const getUserCustomerAccountById = (id, accounts) => accounts[id] || [];
